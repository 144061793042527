import React, { useState } from 'react';
import { initializeApp } from "firebase/app";
import { getAuth, PhoneAuthProvider, RecaptchaVerifier, signInWithCredential, initializeAuth, signInWithPhoneNumber } from "firebase/auth";

function Test() {
    (
        <div>
        </div>
    );
}

export default Test;
