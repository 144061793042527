// src/data/faqs.js
const faqs = {
    login: Array.from({ length: 20 }, (_, index) => ({
        id: index + 1,
        question: [
            "로그인에 실패하는 이유는 무엇인가요?",
            "비밀번호를 잊어버렸을 때 어떻게 해야 하나요?",
            "계정이 잠긴 경우 어떻게 해제하나요?",
            "자동 로그인을 설정할 수 있나요?",
            "다른 기기에서 로그아웃하려면 어떻게 하나요?",
            "비밀번호 변경 주기는 어떻게 설정하나요?",
            "로그인 알림을 받을 수 있나요?",
            "2단계 인증을 활성화하는 방법은 무엇인가요?",
            "로그인 시 오류 코드가 나타나는 이유는?",
            "로그아웃 버튼이 보이지 않아요. 어떻게 하나요?",
            "계정 탈퇴 후 재가입은 가능한가요?",
            "아이디를 기억할 수 있는 기능이 있나요?",
            "이전에 사용하던 비밀번호로 변경할 수 있나요?",
            "비밀번호를 변경해도 로그아웃되지 않나요?",
            "로그인 페이지에서 새로고침 시 정보가 사라집니다.",
            "계정이 일시적으로 잠긴 경우 얼마나 걸리나요?",
            "비밀번호를 입력할 때 보안 점검이 있나요?",
            "로그인 시 보안 질문을 추가할 수 있나요?",
            "휴대전화 번호로 로그인할 수 있나요?",
            "계정 보호를 위한 추가 방법이 있나요?"
        ][index % 20],
        answer: [
            "비밀번호를 여러 번 틀릴 경우 계정이 잠길 수 있습니다. 비밀번호를 재설정하거나 고객센터에 문의하세요.",
            "비밀번호 찾기 옵션을 이용하여 등록된 이메일 또는 휴대전화로 임시 비밀번호를 발급받을 수 있습니다.",
            "고객센터에 문의하거나, 보안 질문을 통해 계정 잠금을 해제할 수 있습니다.",
            "로그인 시 '자동 로그인' 체크박스를 선택하시면 됩니다. 그러나 공용 PC에서는 사용을 권장하지 않습니다.",
            "설정 페이지에서 '모든 기기에서 로그아웃'을 선택하면 다른 기기에서 로그아웃됩니다.",
            "보안 강화를 위해 비밀번호 변경 주기를 설정할 수 있습니다. 설정 페이지에서 주기를 선택하세요.",
            "설정에서 로그인 알림을 활성화하여 로그인 시마다 알림을 받을 수 있습니다.",
            "설정에서 2단계 인증 옵션을 활성화하여 추가 보안을 설정할 수 있습니다.",
            "로그인 시 나타나는 오류 코드는 다양한 원인(잘못된 비밀번호, 서버 오류 등)에 의해 발생할 수 있습니다.",
            "설정 페이지나 프로필에서 로그아웃 옵션을 확인할 수 있습니다.",
            "계정 탈퇴 후 30일 이내에는 재가입이 제한될 수 있습니다. 이후에 재가입이 가능합니다.",
            "로그인 페이지에서 '아이디 기억' 옵션을 선택하면 브라우저에 아이디가 저장됩니다.",
            "보안을 위해 이전에 사용하던 비밀번호로 변경하는 것은 권장되지 않습니다.",
            "일부 사이트에서는 비밀번호를 변경해도 기존 세션이 유지될 수 있습니다. 모든 기기에서 로그아웃하시려면 설정을 확인하세요.",
            "로그인 정보는 새로고침 시 지워질 수 있습니다. 입력 후 바로 로그인하세요.",
            "일시 잠금은 보안 설정에 따라 최대 24시간 동안 지속될 수 있습니다.",
            "비밀번호 입력 시 보안 점검(복잡성 체크 등)이 자동으로 수행됩니다.",
            "설정에서 보안 질문을 추가하여 로그인 보안을 강화할 수 있습니다.",
            "등록된 휴대전화 번호를 통해 계정을 찾거나 로그인할 수 있습니다.",
            "계정 보호를 위해 2단계 인증, 보안 질문, 이메일 알림 등을 활성화할 수 있습니다."
        ][index % 20],
    })),
    terms: Array.from({ length: 20 }, (_, index) => ({
        id: index + 1,
        question: [
            "서비스 이용약관은 어디에서 확인할 수 있나요?",
            "개인정보 처리방침은 어떻게 적용되나요?",
            "약관 변경 시 어떻게 통지되나요?",
            "연령 제한이 있는 서비스는 어떤 것이 있나요?",
            "부적절한 내용 신고는 어떻게 하나요?",
            "서비스 이용 시 유료 결제는 어떻게 처리되나요?",
            "회원 탈퇴 시 개인정보는 어떻게 되나요?",
            "부정 이용 시 어떤 조치가 취해지나요?",
            "저작권 침해에 대한 정책은 어떻게 되나요?",
            "약관에 동의하지 않으면 이용할 수 없나요?",
            "개인정보 수집 항목은 어디에서 확인하나요?",
            "쿠키 수집에 대해 거부할 수 있나요?",
            "회원 정보 수정은 어디에서 할 수 있나요?",
            "약관 동의 철회는 어떻게 하나요?",
            "유료 서비스 환불은 어떻게 처리되나요?",
            "약관은 정기적으로 변경되나요?",
            "서비스 이용 제한 사유는 무엇인가요?",
            "개인정보 보관 기간은 얼마나 되나요?",
            "제3자 제공 정보는 무엇이 있나요?",
            "이용약관 관련 문의는 어디로 하나요?"
        ][index % 20],
        answer: [
            "서비스 이용약관은 '고객센터' 메뉴에서 확인하실 수 있습니다.",
            "개인정보는 회사의 개인정보 처리방침에 따라 보호됩니다.",
            "약관 변경 시 이메일이나 공지사항을 통해 안내해드립니다.",
            "일부 콘텐츠는 연령 제한이 있을 수 있으며, 이용에 제한이 있을 수 있습니다.",
            "신고하기 버튼을 이용하거나 고객센터에 문의하여 신고할 수 있습니다.",
            "유료 결제는 이용 약관에 따라 청구 및 처리됩니다.",
            "회원 탈퇴 시 모든 개인정보는 파기 처리되며, 일부 정보는 보관될 수 있습니다.",
            "부정 이용 시 경고 및 계정 제한 등의 조치가 취해질 수 있습니다.",
            "저작권 침해에 대한 신고는 고객센터를 통해 접수 가능합니다.",
            "이용약관에 동의하지 않으면 서비스 이용이 제한될 수 있습니다.",
            "개인정보 수집 항목은 개인정보 처리방침에서 확인하실 수 있습니다.",
            "브라우저 설정에서 쿠키 수집을 거부할 수 있습니다.",
            "회원 정보는 '내 정보' 메뉴에서 수정할 수 있습니다.",
            "약관 동의 철회는 '회원탈퇴' 메뉴를 통해 가능합니다.",
            "환불 규정에 따라 유료 서비스의 환불이 처리됩니다.",
            "약관은 상황에 따라 수시로 업데이트됩니다.",
            "서비스 이용 제한 사유는 이용약관에 명시되어 있습니다.",
            "개인정보는 법률에 따라 정해진 기간 동안 보관됩니다.",
            "제3자 제공 정보는 동의서에 명시된 항목을 따릅니다.",
            "고객센터를 통해 이용약관 관련 문의를 할 수 있습니다."
        ][index % 20],
    })),
    guide: Array.from({ length: 20 }, (_, index) => ({
        id: index + 1,
        question: [
            "계정 생성 방법을 알려주세요.",
            "프로필 사진은 어떻게 변경하나요?",
            "비밀번호는 어디서 변경할 수 있나요?",
            "개인정보는 어디에서 확인할 수 있나요?",
            "메일 알림 설정은 어떻게 하나요?",
            "비활성화 계정을 복구할 수 있나요?",
            "프로필 정보는 어디서 수정하나요?",
            "앱에서 다크 모드를 켜는 방법은?",
            "계정을 임시로 비활성화하려면?",
            "이용 제한 알림을 받았어요. 이유는?",
            "이메일 주소는 어디서 변경하나요?",
            "이용 내역은 어디에서 볼 수 있나요?",
            "회원 등급은 어떻게 상승하나요?",
            "서비스 사용 중 오류가 발생했어요.",
            "동영상은 어떻게 업로드하나요?",
            "삭제한 콘텐츠를 복구할 수 있나요?",
            "내 친구 목록은 어디에서 확인하나요?",
            "위치 기반 서비스는 어떻게 켜나요?",
            "앱에서 로그아웃하는 방법은?",
            "새로운 기능은 어디서 확인하나요?"
        ][index % 20],
        answer: [
            "회원가입 화면에서 필요한 정보를 입력하여 계정을 생성할 수 있습니다.",
            "프로필 설정에서 사진 변경 옵션을 이용해 프로필 사진을 변경하세요.",
            "비밀번호는 계정 설정에서 변경할 수 있습니다.",
            "개인정보는 '내 정보' 메뉴에서 확인할 수 있습니다.",
            "알림 설정에서 이메일 알림을 켜거나 끌 수 있습니다.",
            "계정 복구는 고객센터에 문의하여 지원받을 수 있습니다.",
            "프로필 수정 옵션을 통해 개인정보를 업데이트할 수 있습니다.",
            "앱 설정에서 다크 모드 옵션을 선택하여 활성화하세요.",
            "계정을 임시 비활성화하려면 계정 설정에서 관련 옵션을 확인하세요.",
            "이용 제한 사유는 고객센터에 문의하여 확인할 수 있습니다.",
            "계정 설정에서 이메일 주소를 변경할 수 있습니다.",
            "이용 내역은 내 활동 기록에서 확인 가능합니다.",
            "회원 등급은 활동 점수에 따라 상승할 수 있습니다.",
            "고객센터나 오류 신고 기능을 이용해 문제를 해결하세요.",
            "업로드 버튼을 통해 동영상을 추가할 수 있습니다.",
            "삭제된 콘텐츠는 복구가 어려울 수 있으니 신중하게 삭제하세요.",
            "내 정보에서 친구 목록을 볼 수 있습니다.",
            "위치 설정에서 위치 기반 서비스를 활성화할 수 있습니다.",
            "설정 메뉴에서 로그아웃 버튼을 찾을 수 있습니다.",
            "새로운 기능은 공지사항이나 업데이트 정보에서 확인 가능합니다."
        ][index % 20],
    })),
};

export default faqs;